<div id="about" class="about-us-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image text-center">
                    <img src="assets/img/creative-agency/3.png" alt="about image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title text-start">
                        <span>About Us</span>
                        <h2>SHYLEAM TECH SOLUTION</h2>
                    </div>
                    <p>Shyleam Tech Solutions is a dynamic and forward-thinking software development company that specializes in crafting
                    cutting-edge solutions for businesses of all sizes. We have two lines of business, Software Development Services and
                    Personal Computer Consultancy. we provide a range of software development capabilities including mobile development, web
                    development, and desktop development. We leverage the latest technologies to transform your ideas into reality.</p>
                    <!-- <ul>
                        <li>Vision & Mision</li>
                        <p>Being a second home where technology and solutions come together as the answer to all
                            challenges faced, it stands to serve and becomes the top choice in creating efficient and
                            professional growth spaces. Our mission is to empower businesses with innovative software
                            solutions that enhance efficiency, drive growth, and exceed customer expectations. We are
                            committed to delivering excellence through a customer-centric approach and continuous
                            technological innovation.</p>
                    </ul> -->

                    <!-- <div class="about-btn">
                        <a routerLink="/agency-landing-one">Get Started</a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="about shape">
    </div>
</div>
