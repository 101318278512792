<div class="newsletter">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>Subscribe Our Newsletter</h3>
                        <p>Your newsletter can provide great value, beyond sales, by informing your buyers with exciting content that resonates with.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="enter your email" name="EMAIL" required autocomplete="off">
                        <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
            <div class="subscribe-shape">
                <img src="assets/img/shape/subscribe-shape.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">
            </div>
        </div>
    </div>
</div>