<div id="portofolio" class="case-study-section ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Some Great Works by Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an
                explanation of it. If an event or situation.</p>
        </div>
        <div class="case-study-slider">
            <owl-carousel-o [options]="caseStudySlides">
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/web-dev.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/shyleam-logo.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">WEB DEVELOPMENT - The system that manages mobility
                                    lifecycle and telecom expense</a></h3>
                            <p>Developed, and rebuilt a system that manages mobility lifecycle and telecom expense using
                                ReactJS. Managed portal users
                                and claims for access to some pages.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/web-dev.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/shyleam-logo.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">WEB DEVELOPMENT - Online Event Website to Display
                                    any Article and Create Scores for Each User When He/she Completes Some Suggested
                                    Activities.</a></h3>
                            <p>Developed, supported, and maintained an Online Event Website using ReactJS for the Front
                                End. Developed a scoring system on this website when users opened the articles and
                                completed the quiz that appeared every 3 minutes with a random question. Made the
                                website comfortable to use on various devices and various browsers</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/mobile-dev.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/shyleam-logo.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">MOBILE DEVELOPMENT - Insurance Application for
                                    Customers</a></h3>
                            <p>Create insurance apps that encompass a wide array of functions, including health claims,
                                customer health benefits data, insurance product sales, and numerous other features.
                                Develop these applications using the Flutter framework with Bloc State Management.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/desktop-dev.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/shyleam-logo.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">DESKTOP DEVELOPMENT - Desktop application to manage
                                    digitalized archives.</a></h3>
                            <p>Develop desktop application using VB.NET to manage digitalized archives in the office
                                which is able to manage the data and create a summary based on criteria.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/desktop-dev.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/shyleam-logo.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">DESKTOP DEVELOPMENT - Desktop application to manage
                                    inventory data</a></h3>
                            <p>Developed desktop application and designing database entity to build an inventory
                                management application using VB.NET which able to manage the data, updating stock
                                information and create summary based on criteria</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
