<div id="principles" class="service-style-four pb-70">
    <div class="container">
        <div class="section-title">
            <span>Principles</span>
            <h2>Vision & Mision</h2>
            <p>Being a second home where technology and solutions come together as the answer to all
            challenges faced, it stands to serve and becomes the top choice in creating efficient and
            professional growth spaces. Our mission is to empower businesses with innovative software
            solutions that enhance efficiency, drive growth, and exceed customer expectations. We are
            committed to delivering excellence through a customer-centric approach and continuous
            technological innovation.</p>
        </div>
    </div>
</div>

<div id="services" class="service-style-four pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Service</span>
            <h2>We are Providing Our Best Services</h2>
            <p>Landset is here to provide you with the best experience of creating a great venture. We help you to run your business with minimal tension.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-computer"></i>
                    <h3>CUSTOM SOFTWARE DEVELOPMENT</h3>
                    <p>Tailored software solutions designed to meet your unique business needs.</p>
                    <p>Web, mobile, and desktop applications that streamline operations and boost productivity.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-web-programming"></i>
                    <h3>Web Development</h3>
                    <p>Responsive and scalable web applications that captivate users and drive online success.</p>
                    <p>content management systems, and web portals.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-ui"></i>
                    <h3>MOBILE APP DEVELOPMENT</h3>
                    <p>iOS app development for smartphones and tablets.</p>
                    <p>Android app development for smartphones and tablets.</p>
                </div>
            </div>
             <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clipboard"></i>
                    <h3>PERSONAL COMPUTER CONSULTANCY</h3>
                    <p>Build personal computer according to usage needs</p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-rocket"></i>
                    <h3>SEO Optimization</h3>
                    <p>With optimized SEO we ensure that you are ranked high in the search and reaching the max customer.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Business Stategy</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to.</p>
                </div>
            </div> -->
        </div>
    </div>
</div>
