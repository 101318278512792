<div id="services" class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Services</span>
            <h2>We Providing Our Best Services</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Business Marketing</h3>
                    <p>Business marketing is a marketing practice of individuals or organizations.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <i class="flaticon-rocket"></i>
                    <h3>Expand SEO</h3>
                    <p>Euzan SEO is a profession involving the design, evaluation, and maintenance.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <i class="flaticon-folder"></i>
                    <h3>Office Management</h3>
                    <p>Euzan Office management is a profession involving the design, implementation and evaluation</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-four wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <i class="flaticon-relationship"></i>
                    <h3>Client Managing</h3>
                    <p>Client managing, that is relationship between company and customers.It's very important for company.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <i class="flaticon-cloud-computing"></i>
                    <h3>Data Collection</h3>
                    <p>Data collection is the process of gathering and measuring information on targeted variables.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-verified"></i>
                    <h3>Secured System</h3>
                    <p>To secure a computer system, it is important to understand the attacks that can be made against it.</p>
                </div>
            </div>
        </div>
    </div>
</div>